import React from "react";

import Classes from "../Styles/Services.module.css";

import service1 from "../assets/service1.png";
import wedding from "../assets/wedding.png";
import car from "../assets/car.png";
import global from "../assets/global.png";
import personal from "../assets/selection.png";
import flag from "../assets/srilanka.png";
import family from "../assets/family.png";
import annual from "../assets/annual.png";
import service3 from "../assets/service3.png";
import service4 from "../assets/service4.png";

function Service() {
  const data = [
    {
      icon: service1,
      title: "Get Best Prices",
      subTitle:
        "Pay through our application and save thousands and get amazing rewards.",
    },
    {
      icon: wedding,
      title: "wedding hire",
      subTitle:
        "Make your wedding unforgettable with our fleet of luxurious and stylish vehicles.",
    },
    {
      icon: car,
      title: "Airport Drop And PickUp",
      subTitle:
      "Your Ride, Your Control: Choose our Drop and Pickup service for a personalized experience.",
    },
    {
      icon: global,
      title: "Foreigners Round Tour",
      subTitle:
      "Ability to povide  a secure and reliable service 24 hours a day.",
    },
    {
      icon: flag,
      title: "Around Country Trip",
      subTitle:
      "Ability to povide  a secure and reliable service 24 hours a day.",
    },
    {
      icon: family,
      title: "Family Trip",
      subTitle:
      "Comfortable Journeys for Every Family Adventure: Reliable Car Hire Services for Your Trip.",
    },
    {
      icon: annual,
      title: "Annual Trip",
      subTitle:
      "Elevate your annual travels with our dependable car hire service.",
    },
    {
      icon: personal,
      title: "Personal Hire",
      subTitle:
      "Experience the convenience of personal hire, designed exclusively for you..",
    },
   
    {
      icon: service3,
      title: "Flexible Payment",
      subTitle:
        " Enjoy the flexible payment through our app and get rewards on every payment.",
    },
    {
      icon: service4,
      title: "Find The Best Near You",
      subTitle:
        "Find the best hotels and places to visit near you.",
    },
  ];

  return (
    <section id="service" className={Classes.service}>
      {data.map((item) => {
        return (
          <div className={Classes.services}>
            <div className={Classes.icon}>
              <img src={item.icon} alt="" />
            </div>
            <h3>{item.title}</h3>
            <p>{item.subTitle}</p>
          </div>
        );
      })}
    </section>
  );
}

export default Service;
