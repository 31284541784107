import React from 'react'
import adishambungalaw from "../assets/adisham bungalaw.jpg";
import ambuluwawa from "../assets/ambuluwawa.jpg";
import bambarakiriella from "../assets/bambarakiri ella.jpg";
import bentotabeach from "../assets/bentota beach.jpg";
import bomburuellafalls from "../assets/bomburu ella falls.jpg";
import bopathella from "../assets/bopath ella.jpg";
import buttalakathirgamamroad from "../assets/buttala kathirgamam road.jpg";
import Dambullacavetamble from "../assets/Dambulla cave tamble.jpg";
import dondra from "../assets/dondra.jpg";

import dunhindafalls from "../assets/dunhinda falls.jpg";
import eighteenbends from "../assets/eighteen bends.jpg";
import gallefort from "../assets/galle fort.jpg";
import hikkaduwa from "../assets/hikkaduwa.jpg";
import junclebeach from "../assets/juncle beach.jpg";
import kadiyanlenafalls from "../assets/kadiyanlena falls.jpg";
import kathirgamam from "../assets/kathirgamam.jpg";
import laxapanafalls from "../assets/laxapana falls.jpg";

import liptonseat from "../assets/lipton seat.jpg";
import lotustower from "../assets/lotus tower.jpg";
import madugangariversaffari from "../assets/madu ganga river saffari.jpg";
import madulsima from "../assets/madulsima.jpg";
import maskeliya from "../assets/maskeliya.jpg";
import mirisacoconuthill from "../assets/mirisa coconut hill.jpg";
import narangala from "../assets/narangala.jpg";
import nationalmuseumcolombo from "../assets/national museum colombo.jpg";
import nilavalibeach from "../assets/nilavali beach.jpg";
import nuwareliya from "../assets/nuwareliya.jpg";
import ohiya from "../assets/ohiya.jpg";
import peradeniya from "../assets/peradeniya.jpg";
import pidurangala from "../assets/pidurangala.jpg";
import pigeonisland from "../assets/pigeon island.jpg";
import pimburattewatank from "../assets/pimburattewa tank.jpg";

import pinnawala from "../assets/pinnawala.jpg";
import ramboda from "../assets/ramboda.jpg";
import ravanaella from "../assets/ravana ella.jpg";
import riverston from "../assets/riverston.jpg";
import Classes from "../Styles/Places.module.css";



import sembuwatta from "../assets/sembuwatta.jpg";
import seraellafalls from "../assets/sera ella falls.jpg";
import sivanolipathamountain from "../assets/sivanolipatha mountain.jpg";
import thalperockbeach from "../assets/thalpe rock beach.jpg";
import waterworldkelaniya from "../assets/water world kelaniya.jpg";
import weligama from "../assets/weligama.jpg";

function Places() {
    const data = [
        
        {
          icon: adishambungalaw,
          
            
        },
      
        {
            icon: ambuluwawa,
           
          },
          
          {
            icon: bambarakiriella,
            },
          {
            icon: bentotabeach,
            },
            {
                icon: bomburuellafalls,
                
                  
              },
            
              {
                  icon: bopathella,
                 
                },
                
                {
                  icon: buttalakathirgamamroad,
                  },
                {
                  icon: dondra,
                  },
                  {
                    icon: dunhindafalls,
                    
                      
                  },
                
                  {
                      icon: eighteenbends,
                     
                    },
                    
                    {
                      icon: gallefort,
                      },
                    {
                      icon: hikkaduwa,
                      },
                      {
                        icon: junclebeach,
                        
                          
                      },
                    
                      {
                          icon: kadiyanlenafalls,
                         
                        },
                        
                        {
                          icon: kathirgamam,
                          },
                        {
                          icon: laxapanafalls,
                          },
                          {
                            icon: liptonseat,
                           
                          },
                          
                          {
                            icon: lotustower,
                            },
                          {
                            icon: madugangariversaffari,
                            },

                            {
                                icon: madulsima,
                                },
                              {
                                icon: maskeliya,
                                },
                                {
                                  icon: mirisacoconuthill,
                                 
                                },
                                
                                {
                                  icon: narangala,
                                  },
                                {
                                  icon: nationalmuseumcolombo,
                                  },

                                  {
                                    icon: nilavalibeach,
                                    },
                                    {
                                      icon: nuwareliya,
                                     
                                    },
                                    
                                    {
                                      icon: ohiya,
                                      },
                                    {
                                      icon: peradeniya,
                                      },

                                      {
                                        icon: pidurangala,
                                        },
                                        {
                                          icon: pigeonisland,
                                         
                                        },
                                        
                                        {
                                          icon: pimburattewatank,
                                          },
                                        {
                                          icon: pinnawala,
                                          },
                                          {
                                            icon: ramboda,
                                            },
                                          {
                                            icon: ravanaella,
                                            },
      
                                            {
                                              icon: riverston,
                                              },
                                              {
                                                icon: sembuwatta,
                                               
                                              },
                                              
                                              {
                                                icon: seraellafalls,
                                                },
                                              {
                                                icon: sivanolipathamountain,
                                                },
                                                {
                                                    icon: thalperockbeach,
                                                    },
                                                    {
                                                      icon: waterworldkelaniya,
                                                     
                                                    },
                                                    
                                                    {
                                                      icon: weligama,
                                                      },
                                                   
      ];
  return (
    <div> <div className="title"><h1>Top Places</h1></div>
        
    <section id="Vehicle" className={Classes.service}>
         {data.map((item) => {
           return (
             <div className={Classes.services}>
               <div className={Classes.icon}>
                 <img src={item.icon} alt="" />
               </div>
               
                
             </div>
           );
         })}
       </section></div>
  )
}

export default Places