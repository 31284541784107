import React, { useState } from "react";

import "../Styles/Recommendation.css";

import nallur from "../assets/nallur.jpg";
import lib from "../assets/jaffnalib.jpg";
import madu from "../assets/madu.jpg";
import thiruketheswaram from "../assets/thirukethiswam.jpg";
import colombo from "../assets/colombo.jpg";
import ella from "../assets/ella.jpg";
import galle from "../assets/galle.jpg";
import sigiriya from "../assets/sigiriya.jpg";
import yalla from "../assets/yallapark.jpg";
import trinco from "../assets/trinco.jpg";

import info1 from "../assets/info1.png";
import info2 from "../assets/info2.png";
import info3 from "../assets/info3.png";

function Recommendation() {
  const [active, setActive] = useState();

  const data = [
    {
      image: nallur,
      title: "Nallur Kandaswamy Kovil",
      subTitle: "Nallur Kandaswamy Kovil or Nallur Murugan Kovil is one of the most significant Hindu temples in the Jaffna District of Northern Province, Sri Lanka.",
      // cost: "38,800",
      // duration: "Approx 2 night trip",
    },
    {
      image: lib,
      title: "Jaffna Public Library",
      subTitle: "Jaffna Public Library is located in Jaffna, Sri Lanka. It is one of Jaffna's most notable landmarks, and is run by the Jaffna Municipal Council. The library was built in 1933 and burnt in 1981.",
      // cost: "54,200",
      // duration: "Approx 2 night trip",
    },
    {
      image: madu,
      title: "Madhu Church at Mannar",
      subTitle: "Madhu is a mystic jungle shrine dedicated to Mother Mary lying in a remote area in Mannar. The church is one of the few churches which is visited by Buddhists as well as Hindus from all over the country.",
      // cost: "45,500",
      // duration: "Approx 2 night trip",
    },
    {
      image: thiruketheswaram,
      title: "Thiruketheeswaram",
      subTitle: "Thiruketheeswaram is an ancient Hindu temple in Manthota, about seven miles north of Mannar Town. According to legend, it was at this ancient temple that Kethu Bhagavan worshiped Lord Siva. Hence the shrine acquired the name of Thiruketheeswaram.",

     
      // cost: "24,100",
      // duration: "Approx 1 night trip",
    },
    {
      image: galle,
      title: "Galle",
      subTitle: "Thiruketheeswaram is an ancient Hindu temple in Manthota, about seven miles north of Mannar Town. According to legend, it was at this ancient temple that Kethu Bhagavan worshiped Lord Siva. Hence the shrine acquired the name of Thiruketheeswaram.",
      // cost: "38,800",
      // duration: "Approx 3 night 2 day trip",
    },
    {
      image: colombo,
      title: "Colombo",
      subTitle: "Colombo is the gateway to Sri Lanka for most travelers—but don’t be tempted to simply pass through this diverse and interesting city",
      // cost: "95,400",
      // duration: "Approx 2 night 2 day trip",
    },
    {
      image: ella,
      title: "Ella",
      subTitle: "Laid-back Ella draws travelers to Sri Lanka's highlands with its mountain forests, tea plantations, and relatively cool climate. The wildlife-rich region is also home to caves, cascades, and secluded temples—plus the Ella Gap, which offers lofty views between a cleft in two hills",
      // cost: "38,800",
      // duration: "Approx 3 night 2 day trip",
    },
    
    {
      image: trinco,
      title: "Koneswaram Temple",
      subTitle: " Koneswaram Temple in Trincomalee is a significant Hindu Temple that has gone through many prosperous years, as well as many turbulent ones during European colonisation",
      // cost: "38,800",
      // duration: "Approx 3 night 2 day trip",
    },
    {
      image: sigiriya,
      title: "Sigiriya",
      subTitle: "Sigiriya, site in central Sri Lanka consisting of the ruins of an ancient stronghold that was built in the late 5th century CE on a remarkable monolithic rock pillar",
      // cost: "38,800",
      // duration: "Approx 3 night 2 day trip",
    },
    {
      image: yalla,
      title: "Yala National Park ",
      subTitle: "Yala National Park is a huge area of forest, grassland and lagoons bordering the Indian Ocean, in southeast Sri Lanka. It’s home to wildlife such as leopards, elephants and crocodiles, as well as hundreds of bird species.",
      // cost: "38,800",
      // duration: "Approx 3 night 2 day trip",
    },
  ];

  const packages = [
    "The Weekend Break",
    "The Package Holiday",
    "The Group Tour",
    "Long Term Slow Travel",
  ];

  return (
    <section id="recommendation" className="recommendation">
      <div className="title">
        <h1>Most Visiting Places</h1>
        {/* <div className="CategoryBar">
          <ul>
            {packages.map((pkg, index) => {
              return (
                <li
                  key={index}
                  className={active === index + 1 ? "Active" : ""}
                  onClick={() => setActive(index + 1)}>
                  {pkg}
                </li>
              );
            })}
          </ul>
        </div> */}
      </div>

      <div className="recommendationBox">
        {data.map((item) => {
          return (
            <div className="box">
              <div className="image">
                <img src={item.image} alt="image" />
              </div>
              <h3>{item.title}</h3>
              <p>{item.subTitle}</p>

              {/* <div className="price">
                <div>
                  <img src={info1} alt="image" />
                  <img src={info2} alt="image" />
                  <img src={info3} alt="image" />
                </div>

                <p>${item.cost}</p>
              </div> */}

              {/* <div className="details">
                <p>1500 kms</p>
                <p>{item.duration}</p>
              </div> */}
            </div>
          );
        })}
      </div>
    </section>
  );
}

export default Recommendation;
