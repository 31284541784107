import React from "react";
import Classes from "../Styles/Vehicle.module.css";


import car1 from "../assets/car1.jpg";
import car2 from "../assets/car2.jpeg";
import car3 from "../assets/car3.jpg";
import car4 from "../assets/car4.jpg";
import car5 from "../assets/car5.jpg";


function Vehicle() {
    const data = [
        
        {
          icon: car2,
          title: "Wagon R Stingray",
          subTitle:
            "Wagon R Stingray: Stylish, spacious, and safe. Comfortable seating, modern design, user-friendly controls. Book now for an enjoyable and efficient ride.",
            feature:"Seat 3 Full Ac"
            
        },
      
        {
            icon: car4,
            title: "KDH Flat Roof Van",
            subTitle:
            " The KDH Flat Roof Van is a versatile and spacious vehicle ideal for transporting passengers or cargo efficiently.",
            feature:"Seat 9 Full Ac",
          },
          
          {
            icon: car1,
            title: " KDH High Roof ",
            subTitle:
              "The KDH High Roof van is an ideal choice for spacious and comfortable transportation. With a higher roof, it provides extra headroom for passengers and ample cargo space.",feature:"Seat 14 Full Ac",
          },
          {
            icon: car5,
            title: "Coaster bus",
            subTitle:
            "The Coaster Bus is a reliable and spacious vehicle, designed for comfortable group travel. It offers a smooth ride and ample seating..",feature:"Seat 32 Full Ac",
          },
      
      ];
  return (
    <div>
        <div className="title"><h1>Available Vehicle</h1></div>
        
 <section id="Vehicle" className={Classes.service}>
      {data.map((item) => {
        return (
          <div className={Classes.services}>
            <div className={Classes.icon}>
              <img src={item.icon} alt="" />
            </div>
            <h3>{item.title}</h3>
            <p>{item.subTitle}</p>
          <h5>{item.feature}</h5>
             
          </div>
        );
      })}
    </section>
    </div>
  )
}

export default Vehicle