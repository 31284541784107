import React from "react";
import ScrollToTop from "./Components/ScrollToTop";
import NavBar from "./Components/NavBar";
import Service from "./Components/Service";
import Testimonials from "./Components/Testimonials";
import Footer from "./Components/Footer";
import Hero from "./Components/Hero";
import Recommendation from "./Components/Recommendation";
import Classes from "./Styles/Footer.module.css";
import Vehicle from "./Components/Vehicle";
import Places from "./Components/Places";

function App() {
  return (
    <div className={Classes.app}>
      
      <NavBar />
      <Hero />
      <Testimonials />
      <Service />
      <Vehicle />
      <Places />
      <Recommendation />
      
      
      <Footer />
      <ScrollToTop />
    </div>
  );
}

export default App;
