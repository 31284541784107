import React from "react";

import Classes from "../Styles/Footer.module.css";
import footerLogo from "../assets/logo1.jpg";

import facebook from "../assets/facebook.svg";


function Footer() {
  const year = new Date();
  return (
    <footer className={Classes.footerContainer}>
      <div className={Classes.footer}>
        <div className={Classes.socialLink}>
        <h4>Copy Right &copy; {year.getUTCFullYear()}</h4> 
          <p>0768663740</p>
       
          <p>Shayanthu9@gmail.com</p>
          <br></br>
        
         <br></br>
         Follow us on social media:
         <br></br>
          <a href="https://www.facebook.com/profile.php?id=100091545561168">
            <img src={facebook} alt="" />
          </a>
         
        </div>

        <div className={Classes.footerLogo}>
          <a href="#hero">
            <img src={footerLogo} alt="" />
            <p>
            SHARUN <span>TOURS & TRAVELS</span>
            </p>
          </a>
        </div>

        <div className={Classes.footerInfo}>
          <h3>All rights reserved.</h3>
         
          <p>
Address: Jaffna,Srilanka
 
<br></br>
Phone: 0768663740<br></br>
Email: Shayanthu9@gmail.com</p>
         <p>Terms of Service | Privacy Policy</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
