import React from "react";

import Classes from "../Styles/Testimonials.module.css";

import sajanthan from "../assets/sajanthan.jpg";

import banner2 from "../assets/card.jpg";
import banner3 from "../assets/card2.jpg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuoteLeft } from "@fortawesome/free-solid-svg-icons";

function Testimonials() {
  return (
    <section id="testimonials" className={Classes.testimonials}>
      

      <div className={Classes.boxContainer}>
        <div className={Classes.testimonialBox}>
          <FontAwesomeIcon icon={faQuoteLeft} className={Classes.Icon} />
          <p>
            
"Discover the simplicity of our website at SHARUN TOURS & TRAVELS.
 Once you've made your decision, our swift and easy booking process ensures a seamless experience. 
Thank you for choosing SHARUN TOURS & TRAVELS. Share the joy with others by recommending our user-friendly site to fellow travelers!"
          </p>

          <div className={Classes.info}>
            <img src={sajanthan} alt="image" />
            <div>
              <h3>Shayanthan</h3>
              <span>OWNER• SHARUN TOURS & TRAVELS</span>
            </div>
          </div>
        </div>
        <div className={Classes.testimonialBox}>
          <FontAwesomeIcon icon={faQuoteLeft} className={Classes.Icon} />
          <p>
            <h2>Book Now</h2>
           <h1>0768663740</h1>
           <h1>Shayanthu9@gmail.com</h1><br></br>
           "We offer a secure and reliable service around the clock, 24 hours a day. 
           
            Your safety and satisfaction are our top priorities."
          </p>

         
        </div>
        
         
          
         
            <div className={Classes.services}>
           
               <div >
                 <img src={banner2} alt="" />
                 <FontAwesomeIcon icon={faQuoteLeft} className={Classes.Icon} />
               </div>
               
                
             </div>
             
         
            <div className={Classes.services}>
        
               <div >
                 <img src={banner3} alt="" />
                 <FontAwesomeIcon icon={faQuoteLeft} className={Classes.Icon} />
               </div>
               
                
             </div>
         

         
        

        <div className={Classes.testimonialBox}>
          <FontAwesomeIcon icon={faQuoteLeft} className={Classes.Icon} />

          <p>
          "Need help booking your trip? Just send us a message or give us a call.
 Reach out at 0768663740 for friendly assistance and easily secure your travel plans.
 We're here to help make your journey memorable!"
 <br></br>
          </p>
          <br></br>
          <div className={Classes.info}>
            <img src={sajanthan} alt="image" />
            <div>
              <h3>Shayanthan</h3>
              <span>OWNER• SHARUN TOURS & TRAVELS</span>
            </div>
          </div>
        </div>

        
      </div>
    </section>
  );
}

export default Testimonials;
